import cocopkg from '../package.json'

let env = 'dev';

const config = {
  // env: env === 'dev' ?
  // {
  //   // 本地服务器:'//localhost:7999',
  //   server:'http://10.3.16.218:8083/api/', ////api.edu.cocorobo.hk
  //   edu: 'http://10.3.16.218:7001/edu/',
  //   //login:'//localhost:7999/login',
  //   origin:'//cocorobo.hk',
  //   version: cocopkg.version,
  //   basename:'',
  //   //edu:'//beta.cocorobo.hk/course',
  //   //room:'//edu.cocorobo.hk/tutor/room',
  // }
  // // {
  // //   server:'//staging.cocorobo.hk/api',
  // //   login:'//staging.cocorobo.hk/login',
  // //   version: cocopkg.version
  // // }
  env: {
    server: '//edu.api.cocorobo.hk/api/',
    api: '//edu.api.cocorobo.hk/',
    edu: '//api.edu.cocorobo.hk/edu/',
    serverHk: 'https://cloud.api.cocorobo.hk/api/',
    apiHk: 'https://cloud.api.cocorobo.hk/',
    apiCom: 'https://cloud.api.cocorobo.com/',
    // edu: '//api.edu.cocorobo.cn/edu/',
    // server: 'http://localhost:8083/api/',
    // api: 'http://localhost:8083/',
    // edu: 'http://localhost:7001/edu/',
    origin: '//cocorobo.hk',
    version: cocopkg.version,
    basename: '',
  }
}

export default config;
